import React from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {CreateToolbar} from "../../CreateToolbar";

export const SubjectCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CreateToolbar {...props}/>}
        variant="outlined">
                <TextInput   validate={required()} source="name" label="Subject name" fullWidth={true} />
                <RichTextInput   validate={required()} source="description" label="Description" />
                <ImageInput source="logourl" label="Related pictures" accept="image/*" className="logourl">
                    <ImageField source="logourl" title="title" />
                </ImageInput>
                <BooleanInput initialValue={true}  source="isactive" />
            </SimpleForm>
    </Create>
);