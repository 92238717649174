import React, { useCallback } from 'react';
import {required ,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

export const RoleEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title=": modify">
            <SimpleForm redirect="list" variant="outlined">
                <TextInput  validate={required()} source="rolename" label="Role name" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
}