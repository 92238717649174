import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { APIUrl } from "./apiClient";
import { responsePathAsArray } from "graphql";
import moment from "moment";
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const UploadFile = (field: string, data: any) => {
	const formData = new FormData();
	formData.append(field, data.rawFile);
	return fetch(`${APIUrl}uploadFile`, {
		method: "POST",
		body: formData
	})
		.then((response) => response.json())
		.then((responseJson) => {
			return responseJson;
		})
		.catch((error) => {
			console.log(error);
		});
};
const getBaseUrl = (url: string): string => {
	if (url.indexOf("?") != -1) {
		url = url.substring(0, url.indexOf("?"));
	}
	return url;
};
export default (
	apiUrl: String,
	httpClient = fetchUtils.fetchJson
): DataProvider => ({
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const filter = params.filter;
		const query = {
			...fetchUtils.flattenObject(params.filter),
			_sort: field,
			_order: order,
			_start: (page - 1) * perPage,
			_end: page * perPage
		};
		var keys = Object.keys(filter);
		let where = "";
		keys.forEach(function (key) {
			var item = filter[key];
			var keyar = key.split("~");
			if (keyar.length > 1) {
				if (keyar[1] == "like") {
					var fld = keyar[0];
					var opr = keyar[1];
					where += `&filter[where][${fld}][${opr}]=%${item}%`;
				}
			} else {
				where += `&filter[where][${key}]=${item}`;
			}
		});
		const loopbackquery = `filter[offset]=${
			(page - 1) * perPage
		}&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
		const url = `${apiUrl}/${resource}?${loopbackquery}`;

		//const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => {
			if (!headers.has("x-total-count")) {
				throw new Error(
					"The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
				);
			}
			let count: string = "10";
			count = headers.get("x-total-count")!.split("/").pop()!;
			return {
				data: json,
				total: parseInt(count, 10)
			};
		});
	},

	getOne: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
			data: json
		})),

	getMany: (resource, params) => {
		/* const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`; */

		var ids = params.ids;
		let where = "";
		if (ids.length == 1) {
			where = `filter[where][id]=` + ids[0];
		} else {
			ids.forEach(function (id, index) {
				where += `&filter[where][or][${index}][id]=${id}`;
			});
		}
		const url = `${apiUrl}/${resource}?${where}`;

		return httpClient(url).then(({ json }) => ({ data: json }));
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const filter = params.filter;
		const query = {
			...fetchUtils.flattenObject(params.filter),
			[params.target]: params.id,
			_sort: field,
			_order: order,
			_start: (page - 1) * perPage,
			_end: page * perPage
		};
		var keys = Object.keys(filter);
		let where = "";
		keys.forEach(function (key) {
			var item = filter[key];
			var keyar = key.split("~");
			if (keyar.length > 1) {
				if (keyar[1] == "ilike") {
					var fld = keyar[0];
					var opr = keyar[1];
					where += `&filter[where][${fld}][${opr}]=${item}`;
				}
			} else {
				where += `&filter[where][${key}]=${item}`;
			}
		});
		if (params.target != undefined) {
			let col = params.target;
			let val = params.id;
			where += `&filter[where][${col}]=${val}`;
		}

		const loopbackquery = `filter[offset]=${
			(page - 1) * perPage
		}&filter[limit]=${perPage}&filter[order]=${field} ${order}${where}`;
		const url = `${apiUrl}/${resource}?${loopbackquery}`;

		//const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => {
			if (!headers.has("x-total-count")) {
				throw new Error(
					"The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
				);
			}
			let count: string = "10";
			count = headers.get("x-total-count")!.split("/").pop()!;
			return {
				data: json,
				total: parseInt(count, 10)
			};
		});
	},

	update: async (resource, params) => {
		let data = params.data;
		if (data.hasOwnProperty("logourl")) {
			if (data.logourl != null) {
				let response = await UploadFile("logourl", data.logourl);
				if (response.files && response.files.length > 0) {
					data.logourl = getBaseUrl(response.files[0].url);
				}
			} else {
				data.logourl = "";
			}
		}
		if (data.hasOwnProperty("imageUrl")) {
			if (data.imageUrl != null) {
				let response = await UploadFile("imageUrl", data.imageUrl);
				if (response.files && response.files.length > 0) {
					data.imageUrl = getBaseUrl(response.files[0].url);
				}
			} else {
				data.imageUrl = "";
			}
		}
		//  if (data.hasOwnProperty("isactive") && data.isactive == "") {
		//      data.isactive = true;
		//  }

		if (data.hasOwnProperty("middlename") && data.middlename == null) {
			data.middlename = "";
		}

		if (data.hasOwnProperty("address") && data.address == null) {
			data.address = "";
		}
		if (data.hasOwnProperty("pincode") && data.pincode == null) {
			data.pincode = "";
		}

		if (data.hasOwnProperty("optionimage")) {
			if (data.optionimage != null) {
				let response = await UploadFile("optionimage", data.optionimage);
				if (response.files && response.files.length > 0) {
					data.optionimage = getBaseUrl(response.files[0].url);
				}
			} else {
				data.optionimage = "";
			}
		}

		let user: any = localStorage.getItem("userId");
		if (user) {
			user = parseInt(user);
		}
		let m = moment();
		data.modifydate = m.toISOString();
		data.modifyby = user;

		// data.createddate = "2016-12-13T15:55:27.920Z";
		// data.createdby = 2;
		// data.modifyby = 2;
		// data.modifydate = "2016-12-13T15:55:27.920Z";

		return httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "PUT",
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json }));
	},

	// json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
	updateMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "PUT",
					body: JSON.stringify(params.data)
				})
			)
		).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

	create: async (resource, params) => {
		let data = params.data;
		if (data.hasOwnProperty("logourl")) {
			if (data.logourl != null) {
				let response = await UploadFile("logourl", data.logourl);
				if (response.files && response.files.length > 0) {
					data.logourl = getBaseUrl(response.files[0].url);
				}
			} else {
				data.logourl = "";
			}
		}
		if (data.hasOwnProperty("isactive") && data.isactive == "") {
			data.isactive = true;
		}
		if (data.hasOwnProperty("optionimage")) {
			if (data.optionimage != null) {
				let response = await UploadFile("optionimage", data.optionimage);
				if (response.files && response.files.length > 0) {
					data.optionimage = getBaseUrl(response.files[0].url);
				}
			} else {
				data.optionimage = "";
			}
		}
		let user: any = localStorage.getItem("userId");
		if (user) {
			user = parseInt(user);
		}
		let m = moment();
		data.createddate = m.toISOString();
		data.createdby = user;

		data.modifydate = m.toISOString();
		data.modifyby = user;

		return httpClient(`${apiUrl}/${resource}`, {
			method: "POST",
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({
			data: { ...params.data, id: json.id }
		}));
	},
	delete: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "DELETE"
		}).then(({ json }) => ({ data: json })),

	// json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	deleteMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "DELETE"
				})
			)
		).then((responses) => ({ data: responses.map(({ json }) => json.id) }))
});
