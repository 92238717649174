import * as http from "./http";

const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
        result = "http://localhost:9115/";
    }
    else {
        result = "https://triviumatsapi.triviumedu.com/";
        
    }
    // result = "http://52.172.190.126:9115/";
    return result;
};
export const APIUrl = getApi();
// export const UploadAPIUrl = "http://52.172.190.126:9115/";
 export const UploadAPIUrl = "https://triviumatsapi.triviumedu.com/";

export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}

export const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

        // swap elements array[i] and array[j]
        // we use "destructuring assignment" syntax to achieve that
        // you'll find more details about that syntax in later chapters
        // same can be written as:
        // let t = array[i]; array[i] = array[j]; array[j] = t
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const GetDashboard = (userid, roleid, StartIndex = 1, PageSize = 20, FilterCondition = "") => {
    let api = `${APIUrl}getdashboard`;
    let data = {
        userid: userid,
        roleid: roleid,
        StartIndex: StartIndex,
        PageSize: PageSize,
        FilterCondition: FilterCondition,
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const GetDashboardHeader = (userid, roleid) => {
    let api = `${APIUrl}getdashboardheader/${userid}/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const isUserAllowedTest = (userid, testid) => {
    let api = `${APIUrl}isuserallowedtest/${userid}/${testid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetAllSubjectTestsByUserID = (userid, subjectid) => {
    let api = `${APIUrl}getallsubjecttestsbyuserid/${userid}/${subjectid}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const CheckValidTestForUser = (userid, testid,ACTION_TOKEN) => {
    let api = `${APIUrl}checkvalidtestforuser/${userid}/${testid}/${ACTION_TOKEN}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetAllSubjects = () => {
    let api = APIUrl + "subjects?filter[where][isactive]=1";
    return http.get(api).then(response => {
        return response;
    });
}

export const GetRestResponse = (id) => {
    let api = `${APIUrl}testresponses/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetSubject = (id) => {
    let api = `${APIUrl}subjects/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getAllTestForSubject = (subjectid) => {
    let api = `${APIUrl}tests?filter[where][subjectid]=${subjectid}&filter[where][isactive]=1`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getTestDetailsById = (testid) => {
    
    let api = `${APIUrl}testdetails/${testid}`;
    
    return http.get(api).then(response => {
        return response;
    });
}
export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const loginUser = (user) => {
    let api = APIUrl + "login";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const loginWithOTP = (mobile) => {
    let api = APIUrl + `getOTP/${mobile}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const verifyOTP = (phone, otp) => {
    let api = APIUrl + `verifyOTP/${phone}/${otp}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getOTP = (phone,token,testId) => {
    let api = APIUrl + `getOTP/${phone}/${token}/${testId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addTestResponse = (userid, subjectid, testid,ACTION_CODE,ACTION_TOKEN) => {
    let api = APIUrl + "addtestresponse";
    let data = {
        userid: userid,
        subjectid: subjectid,
        testid: testid,
        action_code:ACTION_CODE,
        action_token:ACTION_TOKEN

    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getTestResponseEmailLog = (testresponseid, userid, subject) => {
    let api = APIUrl + "gettestresponseemaillog";
    let data = {
        userid: userid,
        subject: subject,
        testResponseID: testresponseid
    }
    return http.post(api, data).then(response => {
        return response;
    });
}


export const addTestResponseDetail = (testresponseid, testquestionid, testquestionoptionid, userid, subject,createddate,ACTION_TOKEN) => {
    let api = APIUrl + "addtestresponsedetail";
    let data = {
        testresponseid: testresponseid,
        testquestionid: testquestionid,
        testquestionoptionid: testquestionoptionid,
        userid: userid,
        subject: subject,
        createddate:createddate,
        token:ACTION_TOKEN
    }
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getUserById = (userId) => {
    let api = `${APIUrl}users/${userId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateUserById = (userId, data) => {
    let api = `${APIUrl}users/${userId}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
