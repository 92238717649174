import React, { useState, useEffect,useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Welcome from './Welcome';
import SubjectCount from './SubjectCount';
import TestCount from './TestCount';
import TestResult from "./TestResult";
import LatestCandidates from './LatestCandidates';
import LatestTest from "./LatestTest";
import CandidateCount from './CandidateCount';
import QuestionCount from './QuestionCount';
import * as apiClient from "../apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';

const myStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    listWithDrawer: {
      marginRight: 300,
    },
    drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 750,
    },
  }));

export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}
export interface ICandidate {
    Candidate_Name: string;
    Email: string;
    MobileNumber: string;
    Joined_On: string;
}
export interface ILatestTest {
    Subject: string;
    Test: string;
    Question: string;
    OptionText: string;
    isRightOption: boolean;
}
export interface ITestResult {
    UserName: string;
    TestDate: string;
    Subject: string;
    TestName: string;
    SecurePercentage: number;
    Result: string;
}
const useStyles = makeStyles({
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});

const Dashboard = () => {
    const [role, setRole] = useState("Student");
    const [rid, setRid] = useState(3);
    const [uid, setUid] = useState(0);
    const [header, setHeader] = useState<IHeader>();
    const [candidates, setCandidates] = useState<ICandidate[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [filterCondition, setFilterCondition] = useState("");
    const [latestTest, setLatestTest] = useState<ILatestTest[]>([]);
    const [testResult, setTestResult] = useState();
    const [selectedTest, setSelectedTest] = useState<any>();
    const [isTestSelected, setisTestSelected] = useState(false);

    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const styles = useStyles({});
    const myclasses = myStyles({});

    const getDashboard = (user: number, role: number) => {
        apiClient.GetDashboard(user, role).then(res => {
            console.log(res);
            if (role != 3) {
                setCandidates(res);
                setPageIndex(pageIndex + 1);
                if (res.length == 20)
                    setHasMore(true);
                else
                    setHasMore(false);
            }
            else {
                setLatestTest(res);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const loadMoreCandidates = () => {
        setLoading(true);
        apiClient.GetDashboard(uid, rid, pageIndex, pageSize, filterCondition).then(res => {
            let carr = [...candidates];
            carr = carr.concat(res);
            setCandidates(carr);
            setPageIndex(pageIndex + 1);
            setLoading(false);
            if (res.length == 20)
                setHasMore(true);
            else
                setHasMore(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    const getDashboardHeader = (user: number, role: number) => {
        apiClient.GetDashboardHeader(user, role).then(res => {
            // console.log(res);
            if (role == 3) {
                setTestResult(res);
            }
            setHeader(res[0]);
        }).catch(err => {
            console.log(err);
        });
    }
    const searchCandidates = () => {
        setPageIndex(1);
        setCandidates([]);
        setLoading(true);
        apiClient.GetDashboard(uid, rid, 1, pageSize, filterCondition).then(res => {
            setCandidates(res);
            setPageIndex(2);
            setLoading(false);
            if (res.length == 20)
                setHasMore(true);
            else
                setHasMore(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    const handleClose = useCallback(() => {
        setisTestSelected(false);
      }, []);
    const onInnerSelectTest = async (item:any) => {
        let ResponseID = item.ResponseID;
        let Subject = item.Subject;
        let UserID = item.UserID;

        let res = await apiClient.getTestResponseEmailLog(ResponseID,UserID,Subject);
        console.log(res);
        setSelectedTest(res); 
        setisTestSelected(true);
    }  
    useEffect(() => {
        let uid = localStorage.getItem("userId");
        let type = localStorage.getItem("type");
        setRole(type!);
        let rid = 3;
        if (type == "Student") {
            rid = 3;
        } else {
            rid = 1;
        }
        setRid(rid);
        setUid(parseInt(uid!));
        getDashboard(parseInt(uid!), rid);
        getDashboardHeader(parseInt(uid!), rid);
    }, []);


    return isXSmall ? (
        <div>
            <div className={styles.flexColumn}>
                <div style={{ marginBottom: '2em' }}>
                    <Welcome />
                </div>
                <div className={styles.flex}>
                    {header && <SubjectCount value={header.SubjectCount} />}
                    {header && <TestCount value={header.TestCount} />}
                </div>
                <div className={styles.singleCol}>
                    <LatestCandidates candidates={candidates} onSelectTest={ (item:any) => { }} />
                </div>
            </div>
        </div>
    ) : isSmall ? (
        <div className={styles.flexColumn}>
            <div className={styles.singleCol}>
                <Welcome />
            </div>
            <div className={styles.flex}>
                {header && <SubjectCount value={header.SubjectCount} />}
                {header && <TestCount value={header.TestCount} />}
            </div>
            <div className={styles.singleCol}>
                <LatestCandidates candidates={candidates}  onSelectTest={ (item:any) => { }} />
            </div>
        </div>
    ) : (
                <React.Fragment>
                    <div className={styles.flexColumn}>
                        <div className={styles.flex}>
                            <div className={styles.leftCol}>
                                <div className={styles.flex}>
                                    <SubjectCount value={header?.SubjectCount} />
                                    <TestCount value={header?.TestCount} />
                                </div>
                            </div>
                            {role == "admin" && <div className={styles.rightCol}>
                                <div className={styles.flex}>
                                    <CandidateCount nb={header?.CandidateCount} />
                                    <QuestionCount value={header?.QuestionCount} />
                                </div>
                            </div>}
                        </div>
                        {role != "admin" && <div className={styles.singleCol}>
                            {role != "admin" && <h3>Test Result</h3>}
                            {role != "admin" && <TestResult result={testResult}  onSelectTest={ (item:any) => { onInnerSelectTest(item); }} />}
                        </div>}
                        <div className={styles.singleCol}>
                            {role == "admin" && <h3 style={{ display: "inline-block", marginRight: 20, }}>Latest Candidates</h3>}
                            {role == "admin" && <div className="candidate-search-parent">
                                <TextField label="Search"
                                    value={filterCondition}
                                    onChange={(event) => { setFilterCondition(event.target.value); }}
                                    variant="outlined" />
                                <Button variant="contained"
                                    onClick={() => { searchCandidates(); }}
                                    color="primary">
                                    Search
                            </Button>
                            </div>}
                            {role == "admin" && <LatestCandidates candidates={candidates} onSelectTest={ (item:any) => { onInnerSelectTest(item); }} />}
                            {role == "admin" && !loading && hasMore && <div className="load-more-btn"
                                onClick={() => { loadMoreCandidates() }}>Load More</div>}
                        </div>
                    </div>
                    <Drawer
                        anchor="right"
                        onClose={handleClose}
                        classes={{
                            paper: myclasses.drawerPaper,
                        }}
                        open={isTestSelected} >
                            {selectedTest && <div className="test-details" dangerouslySetInnerHTML={{ __html: selectedTest[0].Result}}></div>}
                    </Drawer>
                </React.Fragment>
            );
};

export default Dashboard;
