import React from 'react';
import { required ,
    Create, SimpleForm, TextInput, DateInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const RoleCreate = props => (
    <Create {...props} title=": add new ">
        <SimpleForm redirect="list" variant="outlined">
                <TextInput  validate={required()} source="rolename" label="Role name" fullWidth={true} />
            </SimpleForm>
    </Create>
);