import React from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    TabbedForm,Button,
    FormTab,
    Edit, SimpleForm, TextInput,RichTextField ,
    ReferenceInput, SelectInput,EditButton,NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField,ReferenceField, Datagrid, TextField, BooleanField
} from 'react-admin';
import { useStyles } from "../../formStyles";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';
import AddNewQuestionButton from "./AddNewQuestionButton";
import CustomLogoInputDisplay from "../../CustomImage";
import {CreateToolbar} from "../../CreateToolbar";
/* const AddNewQuestionButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: "/testquestions/create",
        state: { test_id: record.id },
      }}
      label="Add a Question"
    >
      <ChatBubbleIcon />
    </Button>
  ); */

export const TestEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props}>
            <TabbedForm variant="outlined"  toolbar={<CreateToolbar {...props}/>}>
                <FormTab label="Test Details">
                    <ReferenceInput label="Subject"   validate={required()} source="subjectid" reference="subjects" fullWidth={true} formClassName={classes.first_inline_input}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="name"   validate={required()} label="Test Name" fullWidth={true} formClassName={classes.last_inline_input} />
                    <TextInput source="title"   validate={required()}  lable="Topics" fullWidth={true} fullWidth={true} formClassName={classes.first_inline_input} />
                    <ReferenceInput label="Test Type"  validate={required()}  source="testtypeid" reference="testtypes" fullWidth={true} formClassName={classes.last_inline_input}>
                        <SelectInput optionText="testtypename" />
                    </ReferenceInput>
                    <ImageInput source="logourl" label="Icon" accept="image/*" className="logourl">
                        <ImageField source="logourl" title="Icon" />
                    </ImageInput>
                    <CustomLogoInputDisplay field="logourl"/>
                    <NumberInput source="testduration"  validate={required()}  label="Test Duration" fullWidth={true} formClassName={classes.one_three_input} />
                    <NumberInput source="passingpercentage"  validate={required()}  label="Passing Percentage" fullWidth={true} formClassName={classes.two_three_input} />
                    <NumberInput source="noofquestions"   validate={required()} label="No of questions" fullWidth={true} formClassName={classes.last_three_input} />
                    <BooleanInput initialValue={true} source="isactive" label="Is Active" />
                </FormTab>
                <FormTab label="Questions">
                    <ReferenceManyField perPage={100} reference="testquestions" target="testid" addLabel={false} fullWidth={true} sort={{ field: 'questionnumber', order: 'ASC' }}>
                        <Datagrid>
                            <ReferenceField label="Subject" source="subjectid" reference="subjects">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Question Type" source="questiontypeid" reference="questiontypes">
                                <TextField source="questiontypename" />
                            </ReferenceField>
                            <RichTextField  source="question" label="Question" />
                            <BooleanField source="mustanswer" label="Must Answer" />
                            <ImageField source="logourl" label="Icon" />
                            <NumberField source="noofchoice" label="Choice Available" />
                            <NumberField source="questionnumber" label="Question Number" />
                            <EditButton />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddNewQuestionButton/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}