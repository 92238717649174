import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ProductIcon from '@material-ui/icons/Collections';
import VisitorIcon from '@material-ui/icons/People';
import ReviewIcon from '@material-ui/icons/Comment';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuMasters';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuMasters: false,
    });
    let history = useHistory();
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("type");
        history.push("/login");
    }
    var userType = localStorage.getItem("type");
    if (!userType) {
        userType = "student";
    }
    const isAdmin = (userType == "admin") ? true : false;
    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            {/* {!isAdmin && <MenuItemLink
                to={`/studentsubjectlist`}
                primaryText="Subjects"
                leftIcon={<SubtitlesIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />} */}
            {!isAdmin && <MenuItemLink
                to={`/editprofile`}
                primaryText="Edit Profile"
                leftIcon={<UserIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}

            {isAdmin && <SubMenu
                handleToggle={() => handleToggle('menuMasters')}
                isOpen={state.menuMasters}
                sidebarIsOpen={open}
                name="Masters"
                icon={<VisitorIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/testtypes`}
                    primaryText="Test Types"
                    leftIcon={<VisitorIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/roletypes`}
                    primaryText="User Roles"
                    leftIcon={<LabelIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/questiontypes`}
                    primaryText="Question Types"
                    leftIcon={<LabelIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>}
            {isAdmin && <MenuItemLink
                to={`/subjects`}
                primaryText="Subject"
                leftIcon={<ReviewIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {isAdmin && <MenuItemLink
                to={`/tests`}
                primaryText="Test"
                leftIcon={<ProductIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {isAdmin && <MenuItemLink
                to={`/users`}
                primaryText="Users"
                leftIcon={<UserIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}

            {isAdmin && <MenuItemLink
                to={`/reports`}
                primaryText="Reports"
                leftIcon={<ReviewIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {<MenuItemLink
                to={`/login`}
                primaryText="Logout"
                leftIcon={<PowerSettingsNewIcon />}
                onClick={handleLogout}
                sidebarIsOpen={open}
                dense={dense}
            />}
            {isAdmin && isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
