import React, { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser,EditGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import { Dash } from "./dashboard/dash";
import customRoutes from './routes';
import englishMessages from './i18n/en';
import subjects from "./screen/subject";
import tests from "./screen/test";
import questions from "./screen/question";
import roles from "./screen/roles";
import users from './screen/user';
import testquestionoptions from "./screen/questionoption";
import VSDataProvider from "./VSDataProvider";
import {APIUrl} from "./apiClient";
import LoginByOTP from "./account/LoginByOTP";
import queryString from 'query-string';
import {
    BrowserRouter as Router,
    Switch,
    useLocation
  } from "react-router-dom";

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    const [dataProvider, setDataProvider] = useState({});
    
    useEffect(() => {
        setDataProvider(VSDataProvider(APIUrl));
    }, []);
    
    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={LoginByOTP}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="roletypes" {...roles}/>
            <Resource name="testtypes" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="questiontypes" list={ListGuesser} edit={EditGuesser}/>
            <Resource name="subjects" {...subjects}/>
            <Resource name="tests" {...tests}/>
            <Resource name="testquestions" {...questions}/>
            <Resource name="testquestionoptions" {...testquestionoptions}/>
            <Resource name="users" {...users}/>
        </Admin>
    );
};

export default App;
