import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function LatestCandidates({ candidates, onSelectTest }) {
    const classes = useStyles();
    return (
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Candidate</StyledTableCell>
                    <StyledTableCell align="right">Joined On</StyledTableCell>
                    <StyledTableCell align="right">Subject</StyledTableCell>
                    <StyledTableCell align="right">Test</StyledTableCell>
                    <StyledTableCell align="right">Test On</StyledTableCell>
                    <StyledTableCell align="right">Percentage</StyledTableCell>
                    <StyledTableCell align="right">Result</StyledTableCell>
                    <StyledTableCell align="right">Photo</StyledTableCell>
                    <StyledTableCell align="right">Details</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {candidates.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                            {row.Candidate_Name} <br /> {row.Email} <br /> {row.MobileNumber}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.Joined_On}</StyledTableCell>
                        <StyledTableCell align="right">{row.Subject}</StyledTableCell>
                        <StyledTableCell align="right">{row.Test}</StyledTableCell>
                        <StyledTableCell align="right">{row.Test_On}</StyledTableCell>
                        <StyledTableCell align="right">{row.Secure_Percentage}</StyledTableCell>
                        <StyledTableCell align="right">{row.Result}</StyledTableCell>
                        <StyledTableCell><img src={row.imageUrl}></img></StyledTableCell>
                        <StyledTableCell><div className="test-details-btn" onClick={() => { onSelectTest(row); }}>Test Details</div></StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}
