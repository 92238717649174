import React, { useCallback } from 'react';
import {    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import CustomLogoInputDisplay from "../../CustomImage";
import {CreateToolbar} from "../../CreateToolbar";

const useStyles = makeStyles(theme => ({
    subjectid:{
        display:'inline-block'
    }, 
    title: {
        display:'inline-block',
        marginLeft: '20px',
        width:'300px'
    }
}));

const SubjectEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props}>
            <SimpleForm redirect="list" toolbar={<CreateToolbar {...props}/>}
            variant="outlined">
                <TextInput   validate={required()} source="name" label="Subject name" fullWidth={true} />
                <RichTextInput   validate={required()} source="description" label="Description" />
                <ImageInput source="logourl" label="Icon" accept="image/*" className="logourl">
                    <ImageField source="logourl" title="title" />
                </ImageInput>
                <CustomLogoInputDisplay field="logourl"/>
                <BooleanInput initialValue={true}  source="isactive" />
            </SimpleForm>
        </Edit>
    );
}
export default SubjectEdit;