import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Register from "./account/Register";
import EditProfile from "./account/EditProfile";
import VerifyOTP from "./account/VerifyOTP";
import LoginByOTP from "./account/LoginByOTP";
import StudentSubjectList from "./student/subjects/SubjectList";
import StudentSubjectDetail from "./student/subjects/SubjectDetail";
import TestHeader from "./student/test/TestHeader";
import Quiz from "./student/test/Quiz";
import QuizResult from "./student/test/QuizResult";

import { Login} from './layout';
export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/register" render={() => <Register />} noLayout />,
    <Route exact path="/admin" render={() => <Login />} noLayout />,
    <Route exact path="/verifyotp" render={() => <VerifyOTP />} noLayout />,
    <Route exact path="/loginbyotp" render={() => <LoginByOTP />} noLayout />,
    <Route exact path="/studentsubjectlist" render={() => <StudentSubjectList />} />,
    <Route exact path="/studentsubjectdetail/:id" render={() => <StudentSubjectDetail />} />,
    <Route exact path="/subjectsopted" render={() => <StudentSubjectList />} />,
    <Route exact path="/testheader/:id" render={() => <TestHeader />} />,
    <Route exact path="/quiz/:id" render={() => <Quiz />} noLayout />,
    <Route exact path="/quizresult" render={() => <QuizResult />} />,
    <Route exact path="/editprofile" render={() => <EditProfile />} />,
];
