import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function TestResult({result, onSelectTest}) {
    const classes = useStyles();
    return (
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>TestName</StyledTableCell>
                    <StyledTableCell align="left">Subject</StyledTableCell>
                    <StyledTableCell align="left">TestDate</StyledTableCell>
                    <StyledTableCell align="right">Percentage</StyledTableCell>
                    <StyledTableCell align="right">Result</StyledTableCell>
                    {/* <StyledTableCell align="right">Details</StyledTableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
                {result && result.map((row,index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                            {row.TestName}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.Subject}</StyledTableCell>
                        <StyledTableCell align="left">{row.TestDate}</StyledTableCell>
                        <StyledTableCell align="right">{row.SecurePercentage}</StyledTableCell>
                        <StyledTableCell align="right">{row.Result}</StyledTableCell>
                        {/* <StyledTableCell><div className="test-details-btn" onClick={() => { onSelectTest(row); }}>Test Details</div></StyledTableCell> */}
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}
