import React from 'react';
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, Button, FormTab,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField
} from 'react-admin';
import { useStyles } from "../../formStyles";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from '@material-ui/core';

export const UserEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} >
            <SimpleForm
                defaultValue={{ middlename: "", pwd: "", roleid: 3, middlename: "", lastname: "",address:"",pincode:"",status:true }}
                variant="outlined">
                <TextInput source="username" validate={[required(), minLength(3), maxLength(20)]} fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="initials" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="firstname" fullWidth={true} validate={required()} formClassName={classes.one_three_input} />
                <TextInput source="middlename" fullWidth={true} formClassName={classes.two_three_input} />
                <TextInput source="lastname" fullWidth={true} validate={required()} formClassName={classes.last_three_input} />
                <TextInput source="email" fullWidth={true} validate={[required(), email()]} formClassName={classes.first_inline_input} />
                <TextInput source="mobile" fullWidth={true} validate={required()} formClassName={classes.last_inline_input} />
                <TextInput source="address" fullWidth={true} />
                <TextInput source="pincode" fullWidth={true} formClassName={classes.first_inline_input} />
                <ReferenceInput label="User Role" source="roleid" reference="roletypes"
                    fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="rolename" />
                </ReferenceInput>
                <TextInput source="pwd" fullWidth={true} validate={[required(), minLength(8), maxLength(15)]} />
                <ImageInput source="imageUrl" label="Photo" accept="image/jpeg">
                 <ImageField source="imageUrl" title="Photo" />
                 </ImageInput>
                 <ImageField source="imageUrl" title="Photo" />
                <BooleanInput source="status" />
            </SimpleForm>
        </Edit>
    );
}