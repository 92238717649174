import React from 'react';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import QuizTimer from "./QuizTimer";
import { useState, useEffect } from 'react';
import * as apiClient from "../../apiClient";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ISubject, ITest, IQuestion, IQuestionOption, ITestResponse } from '../../model/ISubject';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
} from "react-router-dom";
import moment from 'moment';

import { InsertEmoticonTwoTone } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: "column",
        height: "100vh",
        position: "relative",
    },
    main: {
        height: "100%",
        position: "relative",
    },
    testContainer: {
        padding: 10,
        flex: 1,
        border: "1px solid #eee",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 80,
    },
    timerContainer: {
        padding: 10,
        border: "1px solid #eee",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
        margin: "0px 15px",
    },
    testButtons: {
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#cccccc",
        padding: 15,
    },
    buttonContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
    },
    questionContainer: {
        flex: 1,
        overflow: "auto"
    },
    testQuestion: {
        padding: "20px 10px",
    },
    testAnswers: {
        display: "flex",
        flexDirection: "column",
    },
    questionOption: {
        display: "flex",
        border: "1px solid #ccc",
        margin: "10px 0px",
        padding: 10,
        cursor: "pointer",
        "&:hover, &:focus": {
            backgroundColor: "#eee",
        },
        position: "relative"
    },
    selectedQuestionOption: {
        display: "flex",
        backgroundColor: "#bbb",
        border: "1px solid #aaa",
        margin: "10px 0px",
        padding: 10,
        cursor: "pointer",
        "&:hover, &:focus": {
            backgroundColor: "#eee",
        },
        position: "relative"
    },
    optionNumber: {
        padding: "5px 10px",
        backgroundColor: "#ccc",
        border: "1px solid #aaa",
        marginRight: 10,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    optionText: {
        padding: 5,
    },
    testname: {
        margin: 5,
        textAlign: "center",
    },
    checkicon: {
        position: "absolute",
        right: 10,
        top: 10,
        color: "#006400",
    },
    questionnum: {
        position: "absolute",
        right: 20,
        top: 20,

    },
    questionimage: { verticalAlign: "middle" },
    optionimage: { verticalAlign: "middle" },

});

const Quiz = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAllowed, setIsAllowed] = useState(false);
    const [test, setTest] = useState<ITest>();
    const [questionIndexList, setQuestionIndexList] = useState<number[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState<IQuestion>();
    const [selectedOption, setSelectedOption] = useState(-1);
    const [saving, setSaving] = useState(false);
    const [userId, setUserId] = useState(0);
    const [testResponse, setTestResponse] = useState<ITestResponse>();
    const [isSettingOption, setIsSettingOption] = useState(false);
    const [finalReviewList, setFinalReviewList] = useState<any[]>([]);
    const [isReview, setIsReview] = useState(false);
    const classes = useStyles({});
    let { id } = useParams();
    let history = useHistory();

    const CheckValidTestForUser = (userid: number, testid: number) => {
        setIsLoading(true);
        let ACTION_TOKEN=localStorage.getItem('ACTION_TOKEN');
        apiClient.CheckValidTestForUser(userid, testid,ACTION_TOKEN).then(res => {
            if (res && res.length > 0) {
                if (res[0].Result == "Allowed") {
                    setIsAllowed(true);
                    console.log("YES");
                } else {
                    setIsAllowed(false);
                    console.log("NO");
                }
                setIsLoading(false);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const getTestDetailsById = (id: any) => {
        apiClient.getTestDetailsById(id).then(res => {
            // console.log(res);
            let test: ITest = res;
            let tempArr = test.testquestions.map((item, index) => index);
            tempArr = apiClient.shuffle(tempArr);
            tempArr = tempArr.slice(0, 10);
            //console.log(tempArr);
            setQuestionIndexList(tempArr);
            //console.log("All Selected Questions : ");
            // tempArr.forEach(item => {
            //     console.log(test.testquestions[item]);
            // });
            setCurrentQuestion(test.testquestions[tempArr[currentQuestionIndex]]);
            setCurrentQuestionIndex(0);
            setTest(test);
        }).catch(err => {
            console.log(err);
        });
    }
    const previousQuestion = () => {
        if (currentQuestionIndex > 0) {
            let qindex = questionIndexList[currentQuestionIndex - 1];
            setCurrentQuestion(test?.testquestions[qindex]);
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setSelectedOption(-1);
            setIsReview(false);
        }

    }
    const nextQuestion = () => {
        if (currentQuestionIndex < (questionIndexList.length - 1)) {
            let qindex = questionIndexList[currentQuestionIndex + 1];
            setCurrentQuestion(test?.testquestions[qindex]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedOption(-1);
            //console.log("currentQuestionIndex = " + currentQuestionIndex);
            //console.log("Current Question : ");
            //console.log(currentQuestion);
        }
    }
    const prepareForReview = () => {
        let finalQuestions: any[] = [];
        questionIndexList.forEach((item, index) => {
            //console.log(item);
            let tq: any = test?.testquestions[item]!;
            let selOption = tq.testquestionoptions[0];
            let optionid = -1;
            if (tq.selectedOptionIndex != undefined) {
                selOption = tq.testquestionoptions[tq.selectedOptionIndex];
                optionid = selOption.id;
                tq.selectedOption = selOption;
            }
            finalQuestions.push(tq);
        });
        setFinalReviewList(finalQuestions);
        setIsReview(true);
    }
    const finishTest = () => {
        debugger
        setSaving(true);
        localStorage.removeItem("INVITE_CODE");
        let ACTION_CODE=localStorage.getItem('ACTION_CODE');
        let ACTION_TOKEN=localStorage.getItem('ACTION_TOKEN');
        
        apiClient.addTestResponse(userId, test?.subjectid, test?.id,ACTION_CODE,ACTION_TOKEN).then(res => {
            //console.log(res[0]);
            let tr: ITestResponse = res[0];
            setTestResponse(tr);
            questionIndexList.forEach((item, index) => {
                //console.log(item);
                let tq: IQuestion = test?.testquestions[item]!;
                let selOption = tq.testquestionoptions[0];
                let optionid = -1;
                let createddate= "";

                if (tq.selectedOptionIndex != undefined) {
                    selOption = tq.testquestionoptions[tq.selectedOptionIndex];
                    optionid = selOption.id;
                    createddate = tq.createddate; 

                }
                // console.log(tq);
                //console.log(optionid);
                //testresponseid,testquestionid,testquestionoptionid,userid, subject
                apiClient.addTestResponseDetail(tr.ID, tq.id, optionid, userId, test?.title,createddate,ACTION_TOKEN
                ).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            });
            localStorage.setItem("testresult", tr.ID.toString());
            history.push("/quizresult");
        }).catch(err => {
            console.log(err);
        });
    }
    const setQuestionSelectedOption = (index: number) => {
        //console.log("F" + index);
        setIsSettingOption(true);
        let cq: IQuestion = currentQuestion!;
        cq.selectedOptionIndex = index;
        setCurrentQuestion(cq);
        //console.log("Current Question : ");
        //console.log(cq);
        let m = moment();
        let qindex = questionIndexList[currentQuestionIndex];
        let questions = [...test?.testquestions!];
        questions[qindex].createddate = m.toISOString();

        questions[qindex].selectedOptionIndex = index;
        if (test) {
            test.testquestions = questions;
            setTest(test);
        }
        setSelectedOption(index);
        //console.log("A : " + currentQuestion?.selectedOptionIndex);
        //console.log("currentQuestionIndex = " + currentQuestionIndex);
    }
    const getOptionClass = (index: number) => {
        // console.log("getoptionclass" + index);
        if (currentQuestion?.selectedOptionIndex == index) {
            //  console.log("Incondition");
            return classes.selectedQuestionOption;
        }
        //  console.log("Aftercondition");
        return classes.questionOption;
    }
    useEffect(() => {
        console.log("New Question is : ");
        //console.log(currentQuestion);
        console.log("CurrentIndex:" + currentQuestionIndex);
    }, [currentQuestionIndex]);

    useEffect(() => {
        getTestDetailsById(id);
        let uId = localStorage.getItem("userId");
        localStorage.removeItem("INVITE_CODE");
        if (uId) {
            setUserId(parseInt(uId));
            CheckValidTestForUser(parseInt(uId), parseInt(id!));
        }
    }, []);
    return (
        <div className={classes.root}>
            {isLoading && <div className="full-screen-loading">
                <CircularProgress color="secondary" />
                <div>Fetching Test details</div></div>}
            {!isAllowed && <div className="test-not-allowed">
                <div className="not-allowed-message">
                    Either test not found or You have taken this test already. Please try again after 15 days.<br />Thanks! Trivium Education
                </div>
            </div>}
            {!isReview && isAllowed && <div className={classes.main}>
                <div className={classes.testContainer}>
                    {test && <h3 className={classes.testname}>{test.title}</h3>}
                    {questionIndexList && <div className={classes.questionnum}>{currentQuestionIndex + 1} of {questionIndexList.length}</div>}
                    <div className={classes.questionContainer}>
                    
                        {currentQuestion && <div className={classes.testQuestion}>
                            ({currentQuestionIndex + 1}) <div
                                dangerouslySetInnerHTML={{
                                    __html: currentQuestion.question
                                }}></div>
                            {currentQuestion.logourl && <img className={classes.questionimage} src={currentQuestion.logourl} />}
                        </div>}
                        <div className={classes.testAnswers}>
                            {currentQuestion && currentQuestion.testquestionoptions.map((item:any, index) => {
                                return (
                                    <div key={index}

                                        onClick={() => { setQuestionSelectedOption(index); }}
                                        className={getOptionClass(index)}>
                                        <div className={classes.optionNumber}>{item.optiontitle}</div>
                                        
                                        <div className={classes.optionText} 
                                        dangerouslySetInnerHTML={{
                                            __html: item.optiontext
                                        }}
                                        >
                                            
                                        </div>
                                        {item.optionimage && <img className={classes.optionimage} src={item.optionimage!} />}
                                        {selectedOption == index && <div className={classes.checkicon}><CheckCircleIcon fontSize="large" /></div>}
                                        {currentQuestion.selectedOptionIndex == index && <div className={classes.checkicon}><CheckCircleIcon fontSize="large" /></div>}
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>}
            {isReview && currentQuestionIndex >= 9 && <div className="test-review-container">
                <div className="review-caption">Please review the questions and selected answere before submitting the quiz.</div>
                <div className="test-question-row">
                    <div className="test-question">
                        <div className="caption">Question</div>
                    </div>
                    <div className="question-option-selected" >
                        <div className="caption">Your Answer</div>
                    </div>
                </div>
                {finalReviewList && finalReviewList.map((item, index) => {
                    return (
                        <div key={index} className="test-question-row">
                            
                            <div className="test-question">
                                <div className="question-number">({index + 1})</div>
                                <div className="question-text"
                                    dangerouslySetInnerHTML={{
                                        __html: item.question
                                    }}>
                                    </div>
                                {item.logourl && <img className="question-image" src={item.logourl} />}
                            </div>
                            {item.selectedOption && <div className="question-option-selected" >
                                <div className="option-number">{item.selectedOption.optiontitle}</div>
                                <div className="option-text" dangerouslySetInnerHTML={{
                                    __html: item.selectedOption.optiontext
                                }}>
                                </div>
                                {item.selectedOption.optionimage && <img className="option-image" src={item.selectedOption.optionimage!} />}
                            </div>}
                            {!item.selectedOption && <div className="question-option-selected" >
                                <div className="caption">Not answered</div>
                            </div>}
                        </div>
                    )
                })}
            </div>
            }
            {isAllowed && <div className={classes.testButtons}>
                <div className={classes.buttonContainer}>
                    <div>
                        {currentQuestionIndex > 0 && <Button variant="contained" color="primary" onClick={() => { previousQuestion(); }}>
                            Previous Question
                    </Button>}
                    </div>
                    <div>
                        {currentQuestionIndex >= 9 && !isReview && <Button variant="contained" color="primary" onClick={() => { prepareForReview(); }}>
                            Review Test
                    </Button>}

                        {currentQuestionIndex >= 9 && isReview && <Button variant="contained" color="primary" onClick={() => { finishTest(); }}>
                            Finish Test
                    </Button>}
                    </div>
                    <div>
                        {currentQuestionIndex < questionIndexList.length - 1 && <Button variant="contained" color="primary" onClick={() => { nextQuestion(); }}>
                            Next Question
                    </Button>}
                    </div>
                </div>
                <div className={classes.timerContainer}>
                    {test && <QuizTimer onTimerComplete={finishTest} duration={test?.testduration} />}
                </div>
            </div>}
        </div>
    );
};

export default Quiz;
