import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, BooleanField ,EditButton, ImageField} from 'react-admin';

export const SubjectList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Subject name" />
            <ImageField source="logourl" label="Icon" />
            <BooleanField source="isactive" label="Is Active"/>   
            <EditButton/>
        </Datagrid>
    </List>
);