import React from 'react';
import {
    List, Datagrid, TextField, DateField,TextInput,
    ReferenceField, ImageField, EmailField,Filter, SearchInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton
} from 'react-admin';

const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Username" source="username~like" alwaysOn />
        <TextInput label="Email" source="email~like" alwaysOn />
        <TextInput label="Mobile" source="mobile~like" alwaysOn />
    </Filter>
);
export const UserList = props => (
    <List {...props} filters={<UserFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="username" />
            <TextField source="initials" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <EmailField source="email" />
            <TextField source="mobile" />
            <ReferenceField label="User Role" source="roleid" reference="roletypes">
                <TextField source="rolename" />
            </ReferenceField>
            <ImageField source="imageUrl" Label="Photo"/>
            <BooleanField source="status" label="Is Active" />
        </Datagrid>
    </List>
);