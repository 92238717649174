import React from 'react';
import { Button, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";

export const useStyles = makeStyles(theme => ({
    cancel_button: {
        marginLeft: 20,
        marginRight: 20,
    },
    main: {
        display: "flex", width: "100%",
        justifyContent: "space-between",
    },
    btnparent: {
        flex: 1,
    },
}));

export const CreateToolbar = props => {
    const classes = useStyles({});
    const history = useHistory();
    const cancelForm = () => {
        history.goBack();
    }
    return (
        <Toolbar {...props} >
            <div className={classes.main}>
                <div className={classes.btnparent}>
                    <SaveButton  {...props}  />
                    <Button
                        className={classes.cancel_button}
                        variant="contained" color="primary"
                        label="Back" size="medium"
                        startIcon={<CancelIcon />}
                        onClick={cancelForm}
                    />
                </div>
                {props.showdelete && <DeleteButton  {...props} />}
            </div>
        </Toolbar>
    )
};