import React, { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import {
    List, Datagrid, TextField, DateField,
    NumberField, BooleanField, EditButton, ImageField,
    CardActions, CreateButton, ExportButton, RefreshButton
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { RoleCreate } from "./RoleCreate";
import { Drawer, useMediaQuery, makeStyles } from '@material-ui/core';
import { RoleEdit } from './RoleEdit';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 350,
    },
}));

const RoleListActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
        <Toolbar>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </Toolbar>
    );
export const RoleList = props => {
    const classes = useStyles({});
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.push('/roletypes');
    }, [history]);


    return (
        <div className={classes.root}>
            <Route path="/roletypes/:id">
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params 
                    );

                    return (
                        <Fragment>
                            <List title="User role "
                                {...props}
                                className={classnames(classes.list, {
                                    [classes.listWithDrawer]: isMatch,
                                })}
                                actions={<RoleListActions />}
                                perPage={25}
                                sort={{ field: 'id', order: 'DESC' }}
                            >
                                <Datagrid rowClick="show">
                                    <TextField source="rolename" label="Role name" />
                                    <EditButton />
                                </Datagrid>
                            </List>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {isMatch && match.params.id !== 'create' ? (
                                    <RoleEdit
                                        id={match.params.id}
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                                {isMatch && match.params.id === 'create' ? (
                                    <RoleCreate
                                        onCancel={handleClose}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );
}