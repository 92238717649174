import React from 'react';
import {
    List, Datagrid, TextField, DateField,TextInput,
    ReferenceField, ImageField, Filter, SearchInput, ReferenceInput, AutocompleteInput,
    NumberField, BooleanField, EditButton
} from 'react-admin';


const TestFilter = props => (
    <Filter {...props}>
        <TextInput  label="Test Name" source="name~like" alwaysOn />
        <ReferenceInput label="Subject" source="subjectid" reference="subjects" alwaysOn>
            <AutocompleteInput
                optionText={choice => {
                    return (choice.name) ? choice.name : "All";
                }
                }
            />
        </ReferenceInput>
    </Filter>
);
export const GetTestLink = (props) => {
    let {record} = props;
    return (
    <span>http://quiz.triviumedu.com/#/login?invite_code={record.id}</span>
    )
}

export const TestList = props => (
    <List {...props} filters={<TestFilter />}>
        <Datagrid rowClick={false}>
            <EditButton label="Modify"/>
            <GetTestLink label="Test Link"/>
            <ReferenceField label="Subject" source="subjectid" reference="subjects" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="title" label="Title" />
            <ReferenceField label="Test Type" source="testtypeid" reference="testtypes" link={false}>
                <TextField source="testtypename" />
            </ReferenceField>
            <ImageField source="logourl" label="Icon" />
            <BooleanField source="isactive" label="Is Active" />
            <NumberField source="testduration" label="Test Duration" />
            <NumberField source="passingpercentage" label="Passing Percentage" />
            <NumberField source="noofquestions" label="No of questions" />
        </Datagrid>
    </List>
);