import React from 'react';
import {
    List, Datagrid, TextField, DateField,RichTextField,
    ReferenceField, ImageField,
    NumberField, BooleanField, EditButton
} from 'react-admin';

export const QuestionList = props => (
    <List {...props} title="Test questions" sort={{ field: 'questionnumber', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Test" source="testid" reference="tests">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Subject" source="subjectid" reference="subjects">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Question Type" source="questiontypeid" reference="questiontypes">
                <TextField source="questiontypename" />
            </ReferenceField>
            <RichTextField source="question" label="Question" />
            <BooleanField source="mustanswer" label="Must Answer" />
            <ImageField source="logoUrl" label="Icon" />
            <BooleanField source="isactive" label="Is Active" />
            <NumberField source="noofchoice" label="Choice Available" />
            <NumberField source="questionnumber" label="Question Number" />
        </Datagrid>
    </List>
);