import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from '../layout/themes';
import { Location } from 'history';
import logo from "../images/logo.png";
import * as apiClient from "../apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import {
    ImageInput,
    ImageField,
    required
} from "react-admin";

import { Image } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'teal',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        width: 450,
        maxWidth: "100%",
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    backoffice: {
        margin: 10,
        marginTop: 20,
    },
    adminlink: {

    },
}));
interface IMeta {
    touched: boolean;
    error: Error;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField variant="outlined"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    username?: string;
    password?: string;
    imageurl?: any;
}

const { Form } = withTypes<FormValues>();

const Register = ({ location }: { location: Location }) => {
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState();
    const translate = useTranslate();
    const classes = useStyles({});
    const notify = useNotify();
    const login = useLogin();
    let history = useHistory();

    const handleSubmit = async (auth: FormValues) => {

        if(auth.imageurl == "" || auth.imageurl == null){
            alert("Please select profile image.");
            return;
        }

        setLoading(true);
        let innerImageURL = await apiClient.UploadFile("imageurl", auth.imageurl);
        auth.imageurl = innerImageURL;
        console.log(auth);
        apiClient.registerUser(auth).then(async (response) => {
            console.log(response);
            if (response[0].result) {
                localStorage.setItem("mobile", auth.mobile!);
                history.push("/verifyotp");
            } else {
                setLoading(false);
                notify(response[0].message, 'error');
            }

        }).catch((error) => {
            console.log(error);
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                'warning'
            );
        });
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.firstname) {
            errors.firstname = translate('ra.validation.required');
        }
        if (!values.lastname) {
            errors.lastname = translate('ra.validation.required');
        }
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.mobile) {
            errors.mobile = translate('ra.validation.required');
        }
        if (values.mobile && values.mobile.length > 10) {
            errors.mobile = "Mobile number exceeds 10 digits.";
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <HowToRegIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>
                                <img src={logo} />
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="firstname"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="First Name"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="lastname"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Last Name"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Email Address"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="mobile"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Mobile"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <ImageInput source="imageurl"
                                        label="Your Photograph" accept="image/jpeg">
                                        <ImageField source="imageurl" title="Your Photograph" />
                                    </ImageInput>
                                    <em><u>Guidelines to Upload Photograph</u></em>
                                    <em><ul>
                                        <li>The face of the candidate should be straight and at the centre.</li>
                                        <li>Photo, wearing caps and dark glasses will be rejected, as it should not cover the face.</li>
                                        <li>Scanned image file should be in jpg format (Jpeg).</li>
                                        <li>Dimensions of the photograph should be 150 pixels width and 200 pixels height.</li>
                                        <li>Image file should be 10 KB and 50 KB file size</li>
                                        <li><strong>Important –</strong> Candidate need to upload their own photo , failure to which will lead to disqualification of application.</li>
                                    </ul></em>
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    Register
                                </Button>
                                <Button variant="outlined" color="secondary"
                                    fullWidth
                                    href="#login">
                                    Sign In
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                        <div className={classes.backoffice}>
                            <a className={classes.adminlink} href="#admin">Backoffice Login</a>
                        </div>
                    </div>
                </form>
            )}
        />
    );
};

Register.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Register won't get
// the right theme
const RegisterWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Register {...props} />
    </ThemeProvider>
);

export default RegisterWithTheme;
